import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

function getClasses(classes){
  return classes.join(' ');
}


function getRelativeURL(x){
  var domain = x.url.replace('http://','').replace('https://','').split(/[/?#]/)[0];
  if(x !== undefined && domain !== 'localhost' && domain !== 'exp.knockinc.com' && domain !== 'excelinstore.com'){
    return(
      <a href={ x.url} css={{ textDecoration: `none` }} className={getClasses(x.classes)} target="_blank">
        {x.title}
      </a>
    );
  }
  if (x !== undefined && (domain === 'localhost' || domain === 'exp.knockinc.com' || domain === 'excel.instore.com')){
    var linkdata = x;
    var url = x.url.replace(/^.*\/\/[^\/]+/, '');
    return(
      <Link to={url} css={{ textDecoration: `none` }} className={getClasses(linkdata.classes)}>
        {linkdata.title}
      </Link>
    );
  }
}

class NavOneA extends React.Component {
	render() {
	    return(
			<StaticQuery
	            query={graphql`
	              query {
	                allWordpressMenusMenusItems(filter: {name: {eq: "Main Menu Primary"}}) {
					    edges {
					      node {
					        id
					        items {
					          url
					          menu_order
					          classes
					          attr_title
					          target
					          title
					        }
					      }
					    }
					  }
	              	}
	            `}
	            	render={data => (
	                    <ul className="Averta-Bold">
	                      { data.allWordpressMenusMenusItems.edges[0].node.items.map((elem, i) => (
	                        <li key={"link_" + i }>
	                          <Link to={elem.url} css={{ textDecoration: `none` }} activeClassName="active" partiallyActive={true}>
	                            {elem.title}
	                          </Link>
	                        </li>
	                        ))
	                      }
	                    </ul>
	                   )
		        	}
			 />
		)
	}
}


export default NavOneA