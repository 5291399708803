import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

function getClasses(classes){
  return classes.join(' ');
}


function getRelativeURL(x){
  var domain = x.url.replace('http://','').replace('https://','').split(/[/?#]/)[0];
  if(x !== undefined && domain !== 'localhost' && domain !== 'exp.knockinc.com' && domain !== 'excelinstore.com'){
    return(
      <a href={ x.url} css={{ textDecoration: `none` }} className={getClasses(x.classes)} target="_blank">
        {x.title}
      </a>
    );
  }
  if (x !== undefined && (domain === 'localhost' || domain === 'exp.knockinc.com' || domain === 'excel.instore.com')){
    var linkdata = x;
    var url = x.url.replace(/^.*\/\/[^\/]+/, '');
    return(
      <Link to={url} css={{ textDecoration: `none` }} className={getClasses(linkdata.classes)}>
        {linkdata.title}
      </Link>
    );
  }
}


class NavThree extends React.Component {
	render() {
	    return(
			<StaticQuery
	            query={graphql`
	              query {
	                allWordpressMenusMenusItems(filter: {name: {eq: "Footer Menu Primary Column Two"}}) {
					    edges {
					      node {
					        id
					        items {
					          url
					          menu_order
					          classes
					          attr_title
					          target
					          title
					        }
					      }
					    }
					  }
	              	}
	            `}
	             render={data => (
                	<div className="column">
                        { data.allWordpressMenusMenusItems.edges[0].node.items.map((elem, i) => (
	                          <div key={"footer_nav_b_" + i }>
		                          {
		                            getRelativeURL(elem)
		                          }
	                          </div>
	                        ))
                        }
               		</div>
                 )}
  			/>
		)
	}
}


export default NavThree